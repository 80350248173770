<template>
  <div class="receive">
    <div class="receive-calculator">
      <div class="left receive-bx">
        <div class="item">
          <span class="title">{{ $tc('amount') }}</span>
          <input type="number" v-model="amount" placeholder="1000 DFORT" class="amount">
        </div>
        <div class="item">
          <span class="title">{{ $tc('currency') }}</span>
          <v-select :clearable="false" v-model="currency" :options="stakeSelectOptions">
            <template v-slot:option="option">
              <img :src="option.icon" alt="">
              <span class="title">{{ option.label }}</span>
            </template>
            <template #selected-option="{ label, icon }">
              <img :src="icon" alt="">
              <span class="title">{{ label }}</span>
            </template>
          </v-select>
        </div>
        <span class="title fee">{{ $tc('fee') }} 1%</span>
        <span class="title fee mobile">fee: 1%</span>
      </div>
      <div class="right-content receive-bx">
        <span class="title">{{ $tc('should_deposit') }}</span>
        <input type="text" disabled :value='activeCurrencyValue+ " " +currency.label' class="amount">
      </div>
      <!--        <span class="about">Replenishment fee: 1%</span>-->
    </div>
    <div class="receive-content-inner">
      <div class="receive-content">
        <h2 class="header-title" v-html="$tc('deposit_header')"></h2>
        <div class="copy-container">
          <input type="text" class="input" id="receive_input" :value='`0x${wallet.address}`'>
          <div @click="copyToClipboard" class="copy-button">
            <div class="btn-content">
              <img src="../../assets/images/copy.png" class="image" alt="">
              <span class="title">{{ $tc('copy') }}</span>
            </div>
          </div>
        </div>
        <div class="danger-container">
          <img src="../../assets/images/danger.png" class="image" alt="">
          <span class="title">{{ $tc('deposit_warning') }}</span>
        </div>
      </div>
      <div class="blur"></div>
      <div class="background"></div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import vSelect from 'vue-select'

export default {
  data() {
    return {
      currency: {},
      stakeSelectOptions: [],
      amount: ''
    }
  },
  components: {
    vSelect
  },
  computed: {
    ...mapGetters({
      wallet: 'wallet/getWallet'
    }),
    activeCurrencyValue() {
      let num = 1.01 * Number(this.amount) / Number(this.currency?.code)

      return Math.round(num * 100000) / 100000
    }
  },
  methods: {
    ...mapActions({
      getWallet: 'wallet/getWallet'
    }),
    copyToClipboard() {
      let textBox = document.getElementById("receive_input");
      textBox.select();
      document.execCommand("copy");
    },
    getPrices() {
      this.$axios.get('api/v1/wallet/deposit/token-type').then(({data}) => {
        this.stakeSelectOptions = data?.map(item => ({
          label: item.symbol,
          code: item.price,
          icon: require(`@/assets/images/${item.symbol?.toLowerCase()}.png`)
        }));
        this.currency = this.stakeSelectOptions?.find(item => item.label === 'BNB')
      })
    }
  },
  created() {
    this.getWallet()
    this.getPrices()
  }
}
</script>

<style lang="scss" scoped>
.receive {
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 161px);
  min-height: 500px;
  padding: 0 27px;
  @media only screen and (max-width: 1023px) {
    padding: 0 19px 0;
    height: 100%;
    align-items: unset;
  }

  .receive-calculator {
    @include space-between;
    padding: 31px 50px;
    background: #151b2e;
    width: 100%;
    border-bottom: .5px solid rgba(250, 250, 250, .2);
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
    @media only screen and (max-width: 1650px) and (min-width: 1366px) {
      padding: 31px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 768px) {
      padding: 21px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      display: block;
    }
    @media only screen and (max-width: 767px) {
      padding: 0;
      border-radius: unset !important;
    }
    @media only screen and (max-width: 767px) {
      display: block;
      background: transparent;
      border-bottom: unset;
    }

    ::v-deep .v-select {
      width: 140px;
      border: 1px solid rgba(255, 255, 255, 0.38);
      height: 60px;
      border-radius: 10px;
      background: #171717;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      img {
        height: 30px;
        width: 30px;
        object-fit: contain;
      }

      .title {
        color: white;
        font-size: 18px;
        margin-left: 10px;
        margin-bottom: 0;
      }

      .vs__dropdown-toggle {
        width: 100%;
        height: 100%;
        border: unset;
        padding: 0;

        .vs__selected-options {
          flex-grow: unset;
          flex-wrap: unset;
          padding: 0;
          flex-basis: unset;
          width: 100%;
          @include center-children;
        }

        .vs__selected {
          @include center-children;
          width: 100%;
          height: 100%;
          border: unset;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #FFFFFF;
          font-family: "Rubik";
          padding: 0;
          margin: 0;
          @media only screen and (max-width: 767px) {
            width: 100%;
            justify-content: flex-start;
            padding-left: 15px;
          }
        }

        input {
          display: none;
        }
      }

      .vs__dropdown-menu {
        background: transparent;
        border: 1px solid #1e1e1e;
        padding: 0;
        border-top: unset;

        li {
          @include flex;
          padding: 5px 10px 5px 10px;
          background: #171717;

          &:first-child {
            margin-top: 0;
          }

          &:hover, &.vs__dropdown-option--selected, &.vs__dropdown-option--highlight {
            background: $base-color;

            .title {
              color: #0F0E15;
            }
          }

          .title {
            color: white;
            margin-left: 10px;
          }
        }
      }

      .vs__actions {
        //display: none;
        svg {
          path {
            fill: white
          }
        }

        @media only screen and (max-width: 767px) {
          padding: 10px 15px;
        }
      }
    }

    .receive-bx {
      @include flex;
      @media only screen and (max-width: 767px) {
        display: block;
      }

      .item {
        &:first-child {
          margin-right: 30px;

          @media only screen and (max-width: 1440px) and (min-width: 768px) {
            margin-right: 15px;
          }
          @media only screen and (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 25px;
          }
        }
      }
    }

    .amount {
      background: #171717;
      border: 1px solid rgba(255, 255, 255, 0.38);
      border-radius: 10px;
      height: 60px;
      padding: 0 29px;
      font-size: 20px;
      color: white;
      @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        width: 250px;
        padding: 0 15px;
      }
      @media only screen and (max-width: 1023px) and (min-width: 768px) {
        width: 200px;
        padding: 0 10px;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .title {
      font-weight: normal;
      font-size: 18px;
      color: white;
      margin-bottom: 5px;

      &.fee {
        padding-top: 22px;
        margin-bottom: 0;
        margin-left: 30px;

        &.mobile {
          display: none;
          @media only screen and (max-width: 1023px) {
            display: block;
          }
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }

        @media only screen and (max-width: 1440px) and (min-width: 768px) {
          margin-left: 15px;
        }
        @media only screen and (max-width: 1023px) {
          display: none;
        }
      }
    }

    .right-content {
      flex-direction: column;
      align-items: flex-start;
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        margin-top: 25px;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 25px;
      }

      .get-value {
        color: $base-color;
        font-size: 22px;
        font-weight: bold;
        margin-left: 10px;
      }
    }
  }

  .receive-content-inner {
    position: relative;
    font-weight: 400;
    color: #FFFFFF;
    padding-top: 10vh;
    background: #171717;
    height: 100%;
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px;
    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
      padding-top: 7vh;
    }
    @media only screen and (max-width: 767px) {
      padding-top: 25px;
    }

    .receive-content {
      position: relative;
      z-index: 1;
      @include center-children;
      flex-direction: column;
    }

    .header-title {
      display: flex;
      font-size: 46px;
      line-height: 55px;
      text-align: center;
      text-transform: capitalize;
      background: -webkit-linear-gradient(180deg, #42E8E0 0%, #4872F5 100%);
      background: linear-gradient(180deg, #076DE4 0%, #4DE7DF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media only screen and (max-width: 1650px) and (min-width: 1441px) {
        font-size: 40px;
        line-height: 50px;
      }
      @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        font-size: 38px;
        line-height: 47px;
      }
      @media only screen and (max-width: 1365px) and (min-width: 768px) {
        font-size: 35px;
        line-height: 45px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 30px;
        br {
          display: none;
        }
      }
    }

    .description {
      margin-top: 21px;
      font-weight: normal;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      text-transform: capitalize;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 25px;
        margin-top: 15px;
        br {
          display: none;
        }
      }
    }

    .about {
      font-size: 16px;
      margin-top: 19px;
      text-align: center;
      text-transform: capitalize;
    }

    .copy-container {
      @include flex;
      margin-top: 7vh;
      @media only screen and (max-width: 1440px) and (min-width: 1024px) {
        margin-top: 5vh;
      }
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        margin-top: 25px;
      }

      .input {
        background: rgba(30, 30, 30, 0.54);
        border: 1px solid rgba(255, 255, 255, 0.38);
        border-radius: 10px;
        width: 503px;
        height: 60px;
        padding: 0 29px;
        font-size: 20px;
        color: white;
        @media only screen and (max-width: 1440px) and (min-width: 1366px) {
          width: 450px;
          font-size: 18px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          width: 400px;
          font-size: 16px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
          width: 400px;
          font-size: 16px;
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
          height: 54px;
          font-size: 18px;
          padding: 0 15px 0 5px;
        }

        &::placeholder {
          color: rgba(66, 232, 224, 0.4);
        }
      }

      .copy-button {
        @include center-children;
        padding: 0 35px;
        height: 60px;
        background: rgba(66, 232, 224, 0.3);
        border-radius: 70px;
        cursor: pointer;
        margin-left: 20px;
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          padding: 0 25px;
          height: 50px;
        }
        @media only screen and (max-width: 767px) {
          margin-top: 20px;
          padding: 0 16px;
          height: 48.36px;
        }

        .btn-content {
          @include flex;

          img {
            @media only screen and (max-width: 767px) {
              width: 15px;
            }
          }

          .title {
            font-weight: 500;
            font-size: 20px;
            color: $base-color;
            margin-left: 15px;
            @media only screen and (max-width: 767px) {
              margin-left: 9px;
              font-size: 18px;
            }
          }
        }
      }
    }

    .danger-container {
      @include center-children;
      margin-top: 34px;
      @media only screen and (max-width: 767px) {
        width: 100%;
        justify-content: unset;
        margin-top: 25px;
      }

      .image {
        margin-right: 25px;
        width: 30px;
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }

      .title {
        max-width: 65%;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #dc3545;
        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
          font-size: 18px;
          max-width: 80%;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
          max-width: 80%;
        }
        @media only screen and (max-width: 767px) {
          max-width: 100%;
          font-size: 18px;
        }
      }
    }

    .blur {
      position: absolute;
      //height: 323px;
      //width: 100%;
      //background: rgba(0, 224, 255, 0.26);
      //filter: blur(100px);
      //bottom: 0;
      //left: 0;
      //border-radius: 50%;
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(176.75deg, #0040FF 66.05%, rgba(23, 30, 55, 0) 124.52%);
    opacity: 0.1;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    top: 0;
    pointer-events: none;
    left: 0;
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px;
    @media only screen and (max-width: 1023px) {
      border-radius: 0;
    }
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
}
</style>